import { combineReducers } from 'redux';
import {
  subscription as subscriptionModel,
  ghost as ghostModel,
} from '@minerva-analytics/pantheon';

const subscription = combineReducers(subscriptionModel.reducers);
const ghost = combineReducers(ghostModel.reducers);

export { subscription, ghost };
