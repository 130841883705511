import styled from 'styled-components';
import { BodyText } from '@minerva-analytics/pantheon';
import { LARGE, BLACK, PEARL, WHITE, MOBILE, TABLET } from '../../theme';

const ModalTheme = {
  Modal: `
    display: flex;
    flex-direction: row;
    max-width: unset;
    justify-content: space-between;
    width: 49.25rem;
    padding: 2rem 3.75rem;
    background: linear-gradient(to right, ${BLACK} 78%, ${PEARL} 22%);
    min-height: 30rem;
    @media (max-width: ${LARGE}){
      max-width: 36.25rem;
      width 80%;
      background: linear-gradient(to top, ${BLACK} 87%, ${PEARL} 13% );
      display: unset;
      padding: 1.25rem 3.75rem 2rem 3.75rem;
    }
    @media (max-width: ${TABLET}){
      width 70%;
    }
    @media (max-width: ${MOBILE}){
      background: linear-gradient(to top, ${BLACK} 90%, ${PEARL} 10% );
      width: 75%;
      padding: 1rem 2rem;
      min-height: 35rem;
    }
    span {
      font-weight: 800;
      font-size: 2rem;
      color: ${BLACK};
    }
  `,
  Title: `
    color: ${WHITE};
    font-size: 1.75rem;
    line-height: 2.125rem;
  `,
  BodyText: `
    color: ${WHITE};
    line-height: 1.625rem;

    span{
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-weight: 700;
      color: ${WHITE};
    }
  `,
};

const ContentWrapper = styled.div`
  text-align: left;
  width: 55%;
  padding-top: 1rem;
  @media (max-width: ${LARGE}) {
    padding-top: 2.5rem;
    width: unset;
  }
`;

const ClickOutContainer = styled.div`
  bottom: 0;
  position: absolute;
  margin-bottom: 2rem;
`;

const ClickOutLink = styled(BodyText)`
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
`;

const ClickOutText = styled(BodyText)`
  margin: 0;
`;

const LogoImage = styled.img`
  width: auto;
  height: 2rem;
  display: none;
  @media (max-width: ${LARGE}) {
    display: block;
  }
  @media (max-width: ${MOBILE}) {
    display: none;
  }
`;

const MobileLogoImage = styled.img`
  width: auto;
  height: 1.75rem;
  display: none;
  @media (max-width: ${MOBILE}) {
    display: block;
  }
`;

const ModalImage = styled.img`
  width: auto;
  height: 18.75rem;
  @media (max-width: ${LARGE}) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin: auto auto auto 6.25rem;
`;

export {
  ModalTheme,
  ContentWrapper,
  ClickOutContainer,
  ClickOutLink,
  ClickOutText,
  LogoImage,
  ModalImage,
  ImageWrapper,
  MobileLogoImage,
};
