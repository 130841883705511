import styled from 'styled-components';
import { Image } from '@minerva-analytics/pantheon';
import { HONEY, MOBILE, LARGE, EXTRA_LARGE, BLACK, PLUM } from '../../theme';

const HomeTheme = {
  BodyText: `
    color: ${PLUM};
    font-family: 'Lato', sans-serif;
    font-size: 1.125rem;
    line-height: 1.875rem;
  `,
  Title: `
    margin-bottom: 1rem;
    text-align: left;
    white-space: pre-wrap;
    color: ${BLACK};
  `,
};

const HeroContainer = styled.div`
  margin: 0 4rem 0 auto;
  width: 45%;
  @media (max-width: ${LARGE}) {
    width: 90%;
    text-align: left;
    margin: 0 auto;
  }
  @media (max-width: ${MOBILE}) {
    width: 90%;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
  padding: 6rem 0 3rem 0;
  @media (max-width: ${EXTRA_LARGE}) {
    width: 90%;
  }
  @media (max-width: ${LARGE}) {
    padding: 3rem 0 1.5rem 0;
    flex-direction: column;
  }
  @media (max-width: ${MOBILE}) {
    padding: 1.5rem 0;
  }
`;

const MockupImage = styled(Image)`
  border-bottom: 6px solid ${HONEY};
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  @media (max-width: ${LARGE}) {
    margin: 2rem auto 5rem auto;
  }
`;

const Divider = styled.hr`
  width: 5rem;
  border-top: 6px solid ${HONEY};
  margin: 0 0 1.5rem 0;
  display: ${(props) => (props.noDivider ? `none` : ``)};
`;

export {
  HomeTheme,
  HeroContainer,
  HomeContainer,
  ImageContainer,
  Divider,
  MockupImage,
};
