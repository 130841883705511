import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { PLUM } from './theme';

// imported fonts
import LatoHairlineTTF from './assets/Lato/Lato-Hairline.ttf';
import LatoHairlineEOT from './assets/Lato/Lato-Hairline.eot';
import LatoHairlineWOFF from './assets/Lato/Lato-Hairline.woff';
import LatoHairlineWOFF2 from './assets/Lato/Lato-Hairline.woff2';

import LatoThinTTF from './assets/Lato/Lato-Thin.ttf';
import LatoThinEOT from './assets/Lato/Lato-Thin.eot';
import LatoThinWOFF from './assets/Lato/Lato-Thin.woff';
import LatoThinWOFF2 from './assets/Lato/Lato-Thin.woff2';

import LatoLightTTF from './assets/Lato/Lato-Light.ttf';
import LatoLightEOT from './assets/Lato/Lato-Light.eot';
import LatoLightWOFF from './assets/Lato/Lato-Light.woff';
import LatoLightWOFF2 from './assets/Lato/Lato-Light.woff2';

import LatoRegularTTF from './assets/Lato/Lato-Regular.ttf';
import LatoRegularEOT from './assets/Lato/Lato-Regular.eot';
import LatoRegularWOFF from './assets/Lato/Lato-Regular.woff';
import LatoRegularWOFF2 from './assets/Lato/Lato-Regular.woff2';

import LatoMediumTTF from './assets/Lato/Lato-Medium.ttf';
import LatoMediumEOT from './assets/Lato/Lato-Medium.eot';
import LatoMediumWOFF from './assets/Lato/Lato-Medium.woff';
import LatoMediumWOFF2 from './assets/Lato/Lato-Medium.woff2';

import LatoSemiboldTTF from './assets/Lato/Lato-Semibold.ttf';
import LatoSemiboldEOT from './assets/Lato/Lato-Semibold.eot';
import LatoSemiboldWOFF from './assets/Lato/Lato-Semibold.woff';
import LatoSemiboldWOFF2 from './assets/Lato/Lato-Semibold.woff2';

import LatoBoldTTF from './assets/Lato/Lato-Bold.ttf';
import LatoBoldEOT from './assets/Lato/Lato-Bold.eot';
import LatoBoldWOFF from './assets/Lato/Lato-Bold.woff';
import LatoBoldWOFF2 from './assets/Lato/Lato-Bold.woff2';

import LatoHeavyTTF from './assets/Lato/Lato-Heavy.ttf';
import LatoHeavyEOT from './assets/Lato/Lato-Heavy.eot';
import LatoHeavyWOFF from './assets/Lato/Lato-Heavy.woff';
import LatoHeavyWOFF2 from './assets/Lato/Lato-Heavy.woff2';

import LatoItalicTTF from './assets/Lato/Lato-Italic.ttf';
import LatoItalicEOT from './assets/Lato/Lato-Italic.eot';
import LatoItalicWOFF from './assets/Lato/Lato-Italic.woff';
import LatoItalicWOFF2 from './assets/Lato/Lato-Italic.woff2';

import OswaldBoldTTF from './assets/Oswald/Oswald-Bold.ttf';
import OswaldExtraLightTTF from './assets/Oswald/Oswald-ExtraLight.ttf';
import OswaldLightTTF from './assets/Oswald/Oswald-Light.ttf';
import OswaldMediumTTF from './assets/Oswald/Oswald-Medium.ttf';
import OswaldRegularTTF from './assets/Oswald/Oswald-Regular.ttf';
import OswaldSemiBoldTTF from './assets/Oswald/Oswald-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: local('Lato Hairline'), local('Lato-Hairline'),
      url(${LatoHairlineEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoHairlineWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoHairlineWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoHairlineTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 200;
    src: local('Lato Thin'), local('Lato-Thin'),
      url(${LatoThinEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoThinWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoThinWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoThinTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local('Lato Light'), local('Lato-Light'),
      url(${LatoLightEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoLightWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoLightWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoLightTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
      url(${LatoRegularEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoRegularWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoRegularWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoRegularTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'),
      url(${LatoItalicEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoItalicWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoItalicWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoItalicTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: local('Lato Medium'), local('Lato-Medium'),
      url(${LatoMediumEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoMediumWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoMediumWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoMediumTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src: local('Lato Semibold'), local('Lato-Semibold'),
      url(${LatoSemiboldEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoSemiboldWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoSemiboldWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoSemiboldTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'),
      url(${LatoBoldEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoBoldWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoBoldWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoBoldTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    src: local('Lato Heavy'), local('Lato-Heavy'),
      url(${LatoHeavyEOT}) format('embedded-opentype'), /* IE6-IE8 */
      url(${LatoHeavyWOFF2}) format('woff2'), /* Super Modern Browsers */
      url(${LatoHeavyWOFF}) format('woff'), /* Modern Browsers */
      url(${LatoHeavyTTF}) format('truetype'); /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 200;
    src: local('Oswald Extra Light'), local('Oswald-ExtraLight'),
      url(${OswaldExtraLightTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'),
      url(${OswaldLightTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'),
      url(${OswaldRegularTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: local('Oswald Medium'), local('Oswald-Medium'),
      url(${OswaldMediumTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 600;
    src: local('Oswald Semi Bold'), local('Oswald-SemiBold'),
      url(${OswaldSemiBoldTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: local('Oswald Bold'), local('Oswald-Bold'),
      url(${OswaldBoldTTF}) format('truetype');
  }

  html, body, #root {
    margin: 0;
    height: 100%;
    font-family: 'Lato', helvetica, arial, sans-serif;
  }

  li, ul {
    font-family: 'Lato', sans-serif;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: ${PLUM};
    @media (max-width: 36rem){
      font-size: 1rem;
    }
    span {
      font-weight: 700;
    }
  }
`;

export { GlobalStyle };
