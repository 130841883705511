import universal from 'react-universal-component';
import { ghost } from '@minerva-analytics/pantheon';
import { GHOST_API } from './ghost';

const AsyncHome = universal(import(`./views/Home`));
const AsyncConfirm = universal(import(`./views/Confirmation`));
const AsyncAbout = universal(import(`./views/About`));
const AsyncArticles = universal(import(`./views/Articles`));
const AsyncLegal = universal(import(`./views/Legal`));
const AsyncArticle = universal(import(`./views/Article`));
const AsyncIncentive = universal(import(`./views/Incentive`));
const AsyncIncentiveConfirm = universal(
  import(`./views/IncentiveConfirmation`),
);

const { getArticle, selectArticle } = ghost.actions;

const routes = [
  {
    component: AsyncHome,
    key: `home`,
    path: `/`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncConfirm,
    key: `confirmation`,
    path: `/confirm/:id/:token`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncAbout,
    key: `about`,
    path: `/about`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncArticles,
    key: `articles`,
    path: `/articles`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncArticle,
    key: `/articles/:slug`,
    path: `/articles/:slug`,
    exact: true,
    loadData: async (store, match) => {
      const { slug } = match.params;
      store.dispatch(selectArticle(slug));
      await store.dispatch(getArticle(GHOST_API, slug));
    },
  },
  {
    component: AsyncLegal,
    key: `privacy`,
    path: `/privacy`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncLegal,
    key: `terms`,
    path: `/terms`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncLegal,
    key: `cookie`,
    path: `/cookie`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncLegal,
    key: `ccpa`,
    path: `/ccpa`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncIncentive,
    key: `incentive`,
    path: `/ebook`,
    exact: true,
    loadData: () => ({}),
  },
  {
    component: AsyncIncentiveConfirm,
    key: `incentive-confirmation`,
    path: `/ebook-confirmation/:id/:token`,
    exact: true,
    loadData: () => ({}),
  },
];

export default routes;
