import styled from 'styled-components';
import { HONEY, PLUM, MOBILE, TABLET, LARGE } from '../../theme';

const SubscribeContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${LARGE}) {
    align-items: flex-start;
    margin-bottom: 2.5rem;
  }
  @media (max-width: ${MOBILE}) {
    padding: 0;
  }
`;

const SubscribeFormTheme = {
  Input: `
    margin: 0;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.09);
    border-radius: 5px 0 0 5px;
    font-size: 1.125rem;
    border: none;
    background-color: white;
    ::placeholder {
      color: ${PLUM};
      font-weight: 300;
    }
    @media (max-width: ${TABLET}) {
      width: 280px;
    }
    @media (max-width: ${MOBILE}) {
      font-size: 1rem;
      width: 80%;
    }
  `,
  Button: `
    min-width: unset;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.09);
    background-color: ${HONEY};
    border-radius: 0 5px 5px 0;
    color: ${PLUM};
    font-size: 1.125rem;
    width: 12.5rem;
    font-weight: 600;
    :hover{
      background-color: #FFD16F;
      transition: 0.5s;
    }
    @media (max-width: ${TABLET}) {
      max-width: 8rem;
    }
    @media (max-width: ${MOBILE}) {
      max-width: 6rem;
      font-size: 1rem;
    }
  `,
};

export { SubscribeContainer, SubscribeFormTheme };
