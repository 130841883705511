import React from 'react';
import { StyledFooter as PantheonFooter } from '@minerva-analytics/pantheon';
import { BLACK } from '../../theme';

const Footer = () => {
  const links = [
    {
      to: `/terms`,
      title: `TERMS OF USE`,
    },
    {
      to: `/privacy`,
      title: `PRIVACY POLICY`,
    },
    {
      to: `/cookie`,
      title: `COOKIE POLICY`,
    },
    {
      to: `/ccpa`,
      title: `CALIFORNIA POLICY`,
    },
  ];

  const socialButtons = {
    facebook: `https://www.facebook.com/backthenhistory/`,
  };

  return (
    <PantheonFooter
      productName="Back Then History"
      productLogo="https://storage.googleapis.com/backthenhistory-assets/logo_small.png"
      textColor={BLACK}
      font="Lato"
      backgroundColor="white"
      links={links}
      socialButtons={socialButtons}
    />
  );
};

export default Footer;
