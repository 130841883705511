import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  Modal,
  SubTitle,
  Title,
  BodyText,
  event,
} from '@minerva-analytics/pantheon';
import SubscribeForm from '../SubscribeForm';
import SubscribeJump from '../SubscribeJump';
import RockingChairImg from '../../assets/rocking_chair.png';
import {
  ModalTheme,
  ContentWrapper,
  ClickOutContainer,
  ClickOutLink,
  LogoImage,
  ModalImage,
  ImageWrapper,
  ClickOutText,
  MobileLogoImage,
} from './SignUpModal.styles';

const SignUpModal = (props) => {
  const status = useSelector(
    (state) => state.subscription.createSubscriberStatus,
  );

  const { pathname } = useLocation();

  const success = status.loaded && !status.failed;

  const ModalSubscribe = () => (
    <React.Fragment>
      <SubTitle>History with a twist</SubTitle>
      <Title>The Fascinating History of Everyday Objects</Title>
      <BodyText>
        Recall childhood memories, share lessons from the past, or simply brush
        up on your strange history trivia with our daily email newsletter!
      </BodyText>
      <SubscribeForm
        requestData={{
          signup_provider: `modal${pathname.replace(/\//g, '-')}`,
        }}
      />
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={ModalTheme}>
      <Modal
        isShowing={props.isShowing}
        hide={props.hide}
        backdropClosesModal={props.backdropClosesModal}
        modalName="signup-modal"
      >
        <MobileLogoImage
          src="https://storage.googleapis.com/backthenhistory-assets/logo_small.png"
          alt="Back Then History logo for mobile devices"
        />
        <LogoImage
          src="https://storage.googleapis.com/backthenhistory-assets/logo_onlight.png"
          alt="Back Then History logo"
        />
        <ContentWrapper>
          {success ? <SubscribeJump noDivider /> : ModalSubscribe()}
          <ClickOutContainer>
            <ClickOutText>Not Interested?</ClickOutText>
            <ClickOutLink
              onClick={() => {
                props.hide();
                event([`click`, `signup-modal`, `click-out-link`]);
              }}
            >
              Click here to return to the site
            </ClickOutLink>
          </ClickOutContainer>
        </ContentWrapper>
        <ImageWrapper>
          <ModalImage
            src={RockingChairImg}
            alt="card showing the history of rocking chairs"
          />
        </ImageWrapper>
      </Modal>
    </ThemeProvider>
  );
};

SignUpModal.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  backdropClosesModal: PropTypes.bool,
};

export default SignUpModal;
