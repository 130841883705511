import React from 'react';
import { WHITE, BLACK } from '../../theme';
import { NavBar } from './Nav.styles';

const Nav = () => {
  const links = [
    {
      to: '/about',
      title: 'ABOUT US',
    },
    {
      to: '/articles',
      title: 'ARTICLES',
    },
  ];

  return (
    <NavBar
      links={links}
      font="Lato"
      mobileLogo="https://storage.googleapis.com/backthenhistory-assets/logo_small_ondark.png"
      logo="https://storage.googleapis.com/backthenhistory-assets/logo_ondark.png"
      menuItemColor={WHITE}
      headerColor={BLACK}
      menuColor={BLACK}
      menuIconColor={WHITE}
      menuDividerColor="rgba(255,255,255.15)"
      desktopHoverState={{
        borderBottom: `0.5px solid ${WHITE}`,
      }}
      mobileHoverState={{}}
    />
  );
};

export default Nav;
