import { handleActions } from 'redux-actions';
import { getReferralId } from '../actions';

export const initialState = null;

const referral = handleActions(
  new Map([[getReferralId, (state, action) => action.payload]]),
  initialState,
);

export default referral;
