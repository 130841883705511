import GhostContentAPI from '@tryghost/content-api';
import { GHOST_URL, GHOST_KEY } from './constants';

const GHOST_API = new GhostContentAPI({
  url: GHOST_URL,
  key: GHOST_KEY,
  version: `v3`,
});

export { GHOST_API };
