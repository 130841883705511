import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ROUTER_BASENAME } from "./constants";
import App from "./views/App";
import createStore from "./store";

/* eslint-disable */
const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
/* eslint-enable */

const store = createStore(preloadedState);

ReactDOM.hydrate(
  <Provider store={store}>
    <Router basename={ROUTER_BASENAME}>
      <App />
    </Router>
  </Provider>,
  document.getElementById(`root`)
);
