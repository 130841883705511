import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SubTitle,
  Title,
  BodyText,
  Typeform,
} from '@minerva-analytics/pantheon';
import { Divider } from '../../views/Home/Home.styles';
import { TypeformContainer } from './SubscribeJump.styles';

const SubscribeJump = (props) => {
  const email = useSelector((state) => state.subscription.email);

  return (
    <>
      <SubTitle>You&apos;re almost there</SubTitle>
      <Title>Confirm your email!</Title>
      <Divider noDivider={props.noDivider} />
      <BodyText>
        Please check <span>{email}</span> for a message from Back Then History
        to confirm your email.
      </BodyText>
      <BodyText>
        Don&apos;t forget to check your junk folder - just in case!
      </BodyText>
      <TypeformContainer>
        <Typeform formID="lDlvwkyR" parameters={{ email }} />
      </TypeformContainer>
    </>
  );
};

SubscribeJump.propTypes = {
  noDivider: PropTypes.bool,
};

export default SubscribeJump;
