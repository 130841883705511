import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { httpMiddleware } from '@minerva-analytics/pantheon';
import { subscription, ghost } from './pantheon';
import referral from './referral/reducers';

const rootReducer = combineReducers({
  subscription,
  ghost,
  referral,
});

const middlewares = [thunk, httpMiddleware];

if (process.env.API_ENV !== 'production') {
  const { logger } = require(`redux-logger`); // eslint-disable-line

  middlewares.push(logger);
}

export default (preloadedState = {}, customMiddleware = []) =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middlewares, ...customMiddleware),
  );
