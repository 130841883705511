const env = process.env.API_ENV || 'development';

const urls = {
  production: {
    LUCINA_URL: 'https://api-lucina.com',
    SITE_URL: 'https://www.backthenhistory.com',
    STRIPE_KEY:
      'pk_live_51Hr9yqCY36cVYYLvnpoBs3sMF7Dg2Dbzu4F0oMwwhQB3h4OULhFsHjYxrypGndbchMhjGns1hu4kK9hHkFaKVQCw00AVIjvMOK',
  },
  staging: {
    LUCINA_URL: 'https://internal.minerva.dev/staging/lucina-api',
    SITE_URL: 'https://internal.minerva.dev/staging/back-then-history',
    STRIPE_KEY:
      'pk_test_51Hr9yqCY36cVYYLvILwzwUu0FMmUHmeogwt3m4e52gcqBdjngpOXRBZmpBT3HtTcbhmDjwGu4ZdiDxXHoa0Zg1Lo00sdqUcrSF',
  },
  development: {
    LUCINA_URL: 'http://localhost:8080',
    SITE_URL: 'http://localhost:3000',
    STRIPE_KEY:
      'pk_test_51Hr9yqCY36cVYYLvILwzwUu0FMmUHmeogwt3m4e52gcqBdjngpOXRBZmpBT3HtTcbhmDjwGu4ZdiDxXHoa0Zg1Lo00sdqUcrSF',
  },
};

const { LUCINA_URL, API_URL, SITE_URL, STRIPE_KEY } = urls[env];

const LUCINA_DOMAIN = 'backthenhistory.com';

const ROUTER_BASENAME = env === `staging` ? `/staging/back-then-history` : ``;

const GHOST_URL = `https://backthenhistory.minervaarticles.com`;

const GHOST_KEY = `0e5f1550a9e19e2da449cb1637`;

module.exports = {
  LUCINA_URL,
  API_URL,
  SITE_URL,
  STRIPE_KEY,
  LUCINA_DOMAIN,
  ROUTER_BASENAME,
  GHOST_URL,
  GHOST_KEY,
};
