import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  EventRoute,
  saveCampaignParams,
  useModal,
} from '@minerva-analytics/pantheon';
import queryString from 'query-string';

import routes from '../../routes';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { ABTestingContext } from '../../contexts';
import SignUpModal from '../../components/SignUpModal';
import * as referralActions from '../../store/referral/actions';
import { STRIPE_KEY } from '../../constants';

import { GlobalStyle } from '../../index.styles';
import { PageContainer } from './App.styles';
import { starterTheme } from '../../theme';

const stripePromise = loadStripe(STRIPE_KEY);

const App = () => {
  const [isInitialPageView, setIsInitialPageView] = useState(true);
  const { pathname, search, hash } = useLocation();
  const { isShowing, toggleModal } = useModal();
  const dispatch = useDispatch();
  const ABTests = [];

  useEffect(() => {
    const parsed = queryString.parse(search);

    saveCampaignParams(parsed);
  }, []);

  useEffect(() => {
    if (pathname.match(/^\/(about|articles).*$/)) {
      setTimeout(() => {
        const modalTimeout = localStorage.getItem(`modalTimeout`);
        const currentTime = new Date().getTime();
        const tenMinutes = 1000 * 60 * 10;
        if (!modalTimeout || modalTimeout < currentTime) {
          toggleModal();
          localStorage.setItem(`modalTimeout`, currentTime + tenMinutes);
        }
      }, 3000);
    }
  }, [pathname]);

  useEffect(() => {
    if (hash === `#signup`) {
      setTimeout(() => {
        toggleModal();
      }, 3000);
    }

    if (hash.includes(`#ref=`)) {
      const referralId = Number(hash.match(/\d+/)[0]);
      dispatch(referralActions.getReferralId(referralId));
    }
  }, [hash]);

  return (
    <ThemeProvider theme={starterTheme}>
      <Elements stripe={stripePromise}>
        <Helmet>
          <html lang="en" />
          <link
            rel="shortcut icon"
            type="image/ico"
            href="https://storage.googleapis.com/backthenhistory-assets/bth_favicon.ico"
          />
          <link rel="preconnect" href="https://connect.facebook.net/" />
          <link rel="preconnect" href="https://facebook.com" />
          <link
            rel="canonical"
            href={`https://www.backthenhistory.com${pathname}`}
          />
          <title>{`The History of Everyday Objects | Back Then History`}</title>
          <meta
            name="og:title"
            property="og:title"
            content="The History of Everyday Objects | Back Then History"
          />
          <meta
            name="og:description"
            property="og:description"
            content="Sign up for our free daily email newsletter to learn all about the fascinating history behind everyday objects!"
          />
          <meta
            name="description"
            property="description"
            content="Sign up for our free daily email newsletter to learn all about the fascinating history behind everyday objects!"
          />
          <meta
            name="keywords"
            property="keywords"
            content="history, ordinary objects"
          />
          <meta name="og:type" property="og:type" content="website" />
          <meta
            name="og:url"
            property="og:url"
            content="https://www.backthenhistory.com"
          />
          <meta
            name="og:site_name"
            property="og:site_name"
            content="Back Then History"
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://storage.googleapis.com/backthenhistory-assets/bth_favicon.ico"
          />
        </Helmet>
        <GlobalStyle />
        <SignUpModal
          isShowing={isShowing}
          hide={toggleModal}
          backdropClosesModal
        />
        <Nav />
        <PageContainer>
          <ABTestingContext.Provider value={ABTests}>
            <Switch>
              {routes.map((r) => (
                <EventRoute
                  {...r}
                  onPageChange={[
                    () => {
                      /* eslint-disable */
                      if (window._tfa) {
                        window._tfa.push({
                          notify: 'event',
                          name: 'page_view',
                          id: 1308833,
                          'item-url': pathname + search,
                        });
                      }
                      /* eslint-enable */
                      if (!isInitialPageView && window.gtag) {
                        window.gtag(`config`, `UA-175974308-1`, {
                          page_title: document.title,
                          page_path: pathname + search,
                        });
                      } else {
                        setIsInitialPageView(false);
                      }
                    },
                  ]}
                />
              ))}
            </Switch>
          </ABTestingContext.Provider>
        </PageContainer>
        <Footer />
      </Elements>
    </ThemeProvider>
  );
};

export default App;
