/* eslint-disable */
export const trackFacebookConversion = (name, properties) => {
  if (window) {
    if (window.fbq) {
      window.fbq(`track`, name, properties);
    }
  }
};

export const trackTaboolaConversion = (name) => {
  if (window) {
    if (window._tfa) {
      window._tfa.push({ notify: 'event', name: name, id: 1308833 });
    }
  }
};
/* eslint-enable */
