import styled from 'styled-components';
import { EXTRA_LARGE, LARGE, MOBILE, TABLET } from '../../theme';

const TypeformContainer = styled.div`
  width: 110%;
  margin-left: -2rem;
  margin-top: -1rem;

  @media (max-width: ${EXTRA_LARGE}) {
    margin-left: -2rem;
  }

  @media (max-width: ${LARGE}) {
    margin-left: -5rem;
    width: 120%;
  }

  @media (max-width: ${TABLET}) {
    margin-left: -2.5rem;
    width: 115%;
  }

  @media (max-width: ${MOBILE}) {
    margin-left: -1.7rem;
  }
`;

export { TypeformContainer };
