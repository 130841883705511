import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  SubscribeForm as PantheonSubscribeForm,
  getCampaignParams,
} from '@minerva-analytics/pantheon';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LUCINA_URL, LUCINA_DOMAIN } from '../../constants';
import { SubscribeContainer, SubscribeFormTheme } from './SubscribeForm.styles';
import { trackFacebookConversion, trackTaboolaConversion } from '../../utils';
import { WHITE, CANDY_RED } from '../../theme';

const SubscribeForm = ({
  buttonText = 'Subscribe',
  requestData = {},
  ...rest
}) => {
  const piwikParams = typeof window !== `undefined` ? getCampaignParams() : {};

  const location = useLocation();

  const referralId = useSelector((state) => state.referral);

  const onSuccess = () => {
    trackFacebookConversion(
      `CompleteRegistration`,
      `{"content_name": "email-subscription"}`,
    );
    trackTaboolaConversion(`BTHCompleteRegistration`);
  };

  const requestConfig = {
    url: `${LUCINA_URL}/subscribers/`,
    headers: { 'X-Lucina-Domain': LUCINA_DOMAIN },
    data: {
      ...{ ref: referralId },
      ...requestData,
      ...location,
      ...piwikParams,
    },
  };

  return (
    <SubscribeContainer>
      <ThemeProvider theme={SubscribeFormTheme}>
        <PantheonSubscribeForm
          requestConfig={requestConfig}
          buttonText={buttonText}
          successHandler={onSuccess}
          successColor={WHITE}
          failureColor={CANDY_RED}
          {...rest}
        />
      </ThemeProvider>
    </SubscribeContainer>
  );
};

SubscribeForm.propTypes = {
  /* Optional. The button text to use in the form. Defaults to 'Subscribe'  */
  buttonText: PropTypes.string,
  /* Optional. Extra data to pass to the subscribe form */
  requestData: PropTypes.object,
};

export default SubscribeForm;
