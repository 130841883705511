export const BLACK = '#1A1323';
export const CANDY_RED = '#EE2E32';
export const HONEY = '#FFB20F';
export const PEARL = '#EBE9F2';
export const PLUM = '#3D314B';
export const PEACH = '#FFF9F5';
export const WHITE = '#FBFBFB';

export const EXTRA_LARGE = `75rem`;
export const LARGE = `62rem`;
export const TABLET = `48rem`;
export const MOBILE = `36rem`;

const starterTheme = {
  AppLink: `
  `,
  Background: `
  `,
  BodyText: `
    font-family: 'Lato', sans-serif;
    font-size: 1.125rem;
    line-height: 1.875rem;
    @media (max-width: ${MOBILE}){
      font-size: 1rem;
    }
    span {
      font-weight: 700;
    }
  `,
  Button: `
    background-color: ${HONEY};
    color: ${PLUM};
    font-weight: 600;
    font-size: 1.125rem;
    :hover{
      background-color: #FFD16F;
      transition: 0.5s;
    }
  `,
  Card: `
  `,
  ExternalLink: `
    color: ${PEACH};
    font-size: 1.125rem;
  `,
  Footer: `
  `,
  Header: `
    align-items: center;
    position: absolute;
    display: inline-flex;
    background-color: ${BLACK};
  `,
  Image: `
    @media (max-width: ${EXTRA_LARGE}){
      height: 31.25rem;
      width: 31.25rem;
    }
    @media (max-width: ${MOBILE}){
      height: 18.75rem;
      width: 18.75rem;
    }
  `,
  Input: `
  `,
  LoadingSpinner: `
  `,
  Logo: `

  `,
  PiwikButton: `
  `,
  Title: `
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    @media (max-width: ${MOBILE}){
      font-size: 1.75rem;
    }
  `,
  SocialPageButton: `
  `,
  SubTitle: `
    font-size: 1.5rem;
    color: ${CANDY_RED};
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  `,
  BurgerMenu: `

  `,
  IconButton: `
    margin-right: 0.5rem;
    background-color: ${HONEY};

    &:hover{
      background-color: #FFD16F;
    }

    svg {
      fill: ${WHITE};
    }
  `,
};

export { starterTheme };
