import styled from 'styled-components';
import { NavBar as PantheonNav } from '@minerva-analytics/pantheon';

const NavBar = styled(PantheonNav)`
  a {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 2px;
  }
`;

export { NavBar };
